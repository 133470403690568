


















































































































































































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import Content from '@/components/Content.vue'
import Loading from '@/components/Loading.vue'
import Table from '@/components/Table.vue'
import Camera from '@/components/Camera.vue'
import { Farm } from '@/models/Farm'
import { NavigationItem } from '@/models/NavigationItem'
import { firestore } from 'firebase/app'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import { getDownloadUrl, listFiles } from '@/services/Storage'
import { FarmPeriod } from '@/models/FarmPeriod'
import { TableHeader } from '@/models/TableHeader'
import { FarmCameraConverter } from '@/models/converters/FarmCameraConverter'
import { FarmCamera } from '@/models/FarmCamera'

@Component({
  components: {
    Button,
    Content,
    Loading,
    Table,
    Camera
  }
})
export default class FarmDetail extends Vue {
  loading: boolean = true

  headerImageUrl: string = ''
  imageUrls: string[] = []

  farm: Farm = null
  periods: FarmPeriod[] = null
  cameras: FarmCamera[] = []
  headers: TableHeader[] = [
    { key: 'settingDay', title: 'Einstelltermin', classes: ['w-1/3'] },
    { key: 'slaughterDay', title: 'Abholtermin', classes: ['w-1/3'] },
    { key: 'actions', title: 'Bestellen' }
  ]

  async created() {
    this.loading = true

    const snapshot = await firestore()
      .collection('farms')
      .withConverter(new FarmConverter())
      .where('slug', '==', this.$route.params.slug)
      .limit(1)
      .get()

    if (snapshot.docs.length > 0) {
      const farmRef = snapshot.docs[0].ref
      this.farm = snapshot.docs[0].data()

      this.periods = (
        await farmRef
          .collection('periods')
          .withConverter(new FarmPeriodConverter())
          .where('slaughter_day', '>=', new Date())
          .orderBy('slaughter_day', 'asc')
          .get()
      ).docs.map((doc) => doc.data())

      for (const camera of this.farm.cameras) {
        const cam = (
          await camera.reference.withConverter(new FarmCameraConverter()).get()
        ).data()

        this.cameras.push(cam)
      }

      if (this.periods.length > 0) {
        this.farm.nextSlaughterDay = this.periods[0].slaughterDay
      } else {
        this.farm.nextSlaughterDay = new Date()
      }
      this.farm.soldOut = this.periods.filter((p) => p.availableCapacity > 0).length === 0

      const paths = await listFiles(`/farms/${this.farm.id}/images`)
      this.headerImageUrl = await getDownloadUrl(
        paths.find((p) => p.includes('header'))
      )

      this.imageUrls = await Promise.all(
        paths
          .filter((p) => !p.includes('header'))
          .map((path) => getDownloadUrl(path))
      )
    }

    document.title = `${this.farm.name} – Betriebe – meinSCHWEIN`
    this.$route.meta.title = this.farm.name
    this.loading = false
  }
}
